module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Andrew Dent | Front-End Developer Portfolio","short_name":"Andrew Dent","description":"Portfolio website for Andrew Dent, a front-end developer.","background_color":"hsl(186, 62%, 16%)","theme_color":"hsl(186, 62%, 16%)","cache_busting_mode":"query","crossOrigin":"use-credentials","display":"browser","icon":"src/images/favicons/icon.png","orientation":"portrait","start_url":"/","legacy":true,"theme_color_in_head":true,"include_favicon":true,"cacheDigest":"40f4798ce042fb04af82d0ef1b11c9f1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
